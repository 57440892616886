let adminDomain = 'admin.uat.shariot.com'
let dealerDomain = 'dealer.uat.shariot.com'
let apiDomain = 'https://admin-uat.shariot.com'
let apiUrl

if (location.hostname == adminDomain) {
	apiUrl = `${apiDomain}/api/`
} else if (location.hostname == dealerDomain) {
	apiUrl = `${apiDomain}/dealer/api/`
}

export const environment = {
	env: 'staging',
	appName: 'Shariot',
	production: false,
	apiDomain: apiDomain,
	adminDomain: adminDomain,
	dealerDomain: dealerDomain,
	appVersion: 'v2.0.22',
	USERDATA_KEY: 'authf649fc9a3f33',
	apiUrl: apiUrl,
	websocket: 'wss://tracker.uat.shariot.com:22703',
	ws_token: 'test1234',
	timezone: 8,
	appDemos: '',
	appThemeName: '',
	appPreviewUrl: '',
	appPurchaseUrl: '',
	appPreviewDocsUrl: '',
	appPreviewChangelogUrl: '',
}
