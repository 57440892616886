<div class="card">
	<div class="card-header card-advanced-search">
		<span class="menu-title" translate="MENU.REGISTER.ADVANCEDSEARCH"></span>
		<div class="d-flex align-items-center gap-4">
			<div (click)="copyParams()" class="cursor-pointer">
				<small class="badge badge-light-primary d-flex align-items-center">
					<span class="bi bi-link-45deg"></span>
					<span class="ms-2">Copy Search Terms URL</span>
				</small>
			</div>
			<div (click)="saveSelectedConditions()" class="cursor-pointer">
				<small class="badge badge-light-primary d-flex align-items-center">
					<span class="bi bi-save2"></span>
					<span class="ms-2">Save</span>
				</small>
			</div>
		</div>
	</div>

	<form novalidate="novalidate" (ngSubmit)="triggerSearch($event)">
		<div class="card-body row">
			<div
				class="col-sm-12 col-md-6 d-flex align-items-center gap-2 my-4"
				*ngFor="let selectedCondition of selectedConditions; let i = index">
				<div class="row w-100 d-flex align-items-center">
					<div class="col-3 text-center" *ngIf="selectedConditions[i].type">
						<span class="fs-7">{{ selectedConditions[i].text }}</span>
					</div>
					<div class="col-3" *ngIf="!selectedConditions[i].type">
						<select
							[disabled]="isLoading"
							class="form-select form-select-sm"
							[(ngModel)]="selectedConditions[i].type"
							[ngModelOptions]="{ standalone: true }"
							(change)="selectFilter(i)">
							<option
								[selected]="condition.value == null || condition.value == ''"
								*ngFor="let condition of conditions(i)"
								value="{{ condition.value }}"
								[disabled]="!condition.value">
								<span class="menu-title">{{ condition.text }}</span>
							</option>
						</select>
					</div>

					<div class="col-9 d-flex align-items-center">
						<div class="w-100" *ngIf="!selectedConditions[i]?.formType || selectedConditions[i]?.formType == 'text'">
							<input
								type="text"
								class="form-control form-control-sm"
								[(ngModel)]="selectedConditions[i].value"
								[ngModelOptions]="{ standalone: true }" />
						</div>

						<div class="w-100" *ngIf="selectedConditions[i]?.formType == 'textarea'">
							<textarea
								type="text"
								rows="3"
								class="form-control form-control-sm"
								[(ngModel)]="selectedConditions[i].value"
								[ngModelOptions]="{ standalone: true }"></textarea>
						</div>

						<div class="w-100" *ngIf="selectedConditions[i]?.formType == 'number'">
							<div class="input-group">
								<select
									class="form-select form-select-sm"
									[(ngModel)]="selectedConditions[i].operator"
									[ngModelOptions]="{ standalone: true }">
									<option value=">">></option>
									<option value=">=">>=</option>
									<option value="=">=</option>
									<option value="<"><</option>
									<option value="<="><=</option>
								</select>
								<input
									type="text"
									[(ngModel)]="selectedConditions[i].value"
									[ngModelOptions]="{ standalone: true }"
									class="form-control form-control-sm w-250px"
									(input)="validateNumberInput($event)" />
							</div>
						</div>

						<div class="w-100" *ngIf="selectedConditions[i]?.formType == 'date'">
							<div class="input-group d-flex">
								<select
									(change)="onOperatorChange($event, i)"
									class="form-select form-select-sm flex-1"
									[(ngModel)]="selectedConditions[i].operator"
									[ngModelOptions]="{ standalone: true }">
									<option value="<"><</option>
									<option value="=">=</option>
									<option value=">">></option>
									<option value="between">Between</option>
								</select>
								<input
									*ngIf="selectedConditions[i]?.mode == 'range'"
									name="date"
									type="text"
									class="form-control flex-3"
									[mode]="'range'"
									[(ngModel)]="selectedConditions[i].value"
									[ngModelOptions]="{ standalone: true }"
									[dateFormat]="selectedConditions[i]?.dateFormat || 'Y-m-d'"
									[enableTime]="selectedConditions[i]?.enableTime || false"
									[options]="{ minDate: selectedConditions[i]?.minDate }"
									mwlFlatpickr />
								<input
									*ngIf="selectedConditions[i]?.mode != 'range'"
									name="date"
									type="text"
									class="form-control flex-3"
									[(ngModel)]="selectedConditions[i].value"
									[ngModelOptions]="{ standalone: true }"
									[dateFormat]="selectedConditions[i]?.dateFormat || 'Y-m-d'"
									[enableTime]="selectedConditions[i]?.enableTime || false"
									[options]="{ minDate: selectedConditions[i]?.minDate }"
									mwlFlatpickr />
							</div>
						</div>
						<div class="w-100" *ngIf="selectedConditions[i]?.formType == 'select'">
							<ng-select
								[(ngModel)]="selectedConditions[i].value"
								[ngModelOptions]="{ standalone: true }"
								[multiple]="selectedConditions[i]?.multiple"
								class="custom"
								[clearable]="true"
								[clearOnBackspace]="true"
								bindLabel="text"
								bindValue="value">
								<ng-option
									[disabled]="option.value === null"
									[selected]="option.value === null"
									*ngFor="let option of selectedConditions[i].options"
									[value]="option.value"
									class="form-control">
									<span> {{ option.text }} </span>
								</ng-option>
							</ng-select>
						</div>

						<div class="mx-6" *ngIf="selectedCondition.show; else show"></div>
						<ng-template #show>
							<div class="mx-4 cursor-pointer" (click)="removeCondition(i)">
								<span class="bi bi-x-lg text-danger"></span>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
		<div class="px-8 pt-2 pb-6 border-top">
			<button type="button" class="btn btn-primary btn-sm mt-3 me-3" (click)="addCondition()" [disabled]="isDisabled()">
				<div class="d-flex align-items-center gap-2">
					<span class="bi bi-plus-lg"></span>
					<span translate="ACTION.ADD"></span>
				</div>
			</button>
			<button type="button" [disabled]="isLoading" class="btn btn-primary btn-sm mt-3 me-3" (click)="triggerReset()">
				<div class="d-flex align-items-center gap-2">
					<span class="bi bi-arrow-clockwise"></span>
					<span translate="ACTION.RESET"></span>
				</div>
			</button>
			<button [disabled]="isLoading" class="btn btn-primary btn-sm mt-3" type="submit">
				<div class="d-flex align-items-center gap-2">
					<span class="bi bi-search"></span>
					<span translate="ACTION.SEARCH"></span>
				</div>
			</button>
		</div>
	</form>
</div>
