import { DatePipe } from '@angular/common'
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import { ReportUserService } from '@services/report/user.service'
import { cloneDeep } from 'lodash'
@Component({
	selector: 'app-report-select',
	styleUrls: ['./select.component.scss'],
	templateUrl: './select.component.html',
})
export class ReportSelectComponent implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('dateContent', { static: false }) dateContent: ElementRef

	selectedDate: Record<any, any> = {
		type: 'TODAY',
		value: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
	}

	@Input() date: any
	@Input() isLoading: boolean = false
	@Output() change: EventEmitter<any> = new EventEmitter<any>()

	allMonths: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

	selectedYear: string = new Date().getFullYear().toString()
	selectedMonth: string | null = this.allMonths[new Date().getMonth()]

	constructor(
		private datePipe: DatePipe,
		private reportUserService: ReportUserService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.date?.type) {
			if (this.date.type != this.selectedDate.type || this.date.value != this.selectedDate.value) {
				this.selectedDate = this.date
				if (this.selectedDate.type == 'MONTH_YEAR') {
					let values = this.selectedDate.value.split('-')
					this.selectedMonth = this.allMonths[values[1] - 1]
					this.selectedYear = values[0]
					this.getDateRange()
				} else if (this.selectedDate.type == 'YEAR') {
					let value = this.selectedDate.value.split('to')[0].split('-')[0]
					this.selectedMonth = null
					this.selectedYear = value
					this.getDateRange()
				}

				this.onDateChange(
					{
						target: {
							value: this.selectedDate.value,
						},
					},
					false
				)
			}
		}
	}

	firstClick: number = 1

	onDateChange(e: any, isGetData: boolean = false): void {
		let newValue = e?.target?.value

		if (newValue) {
			const resultToday: any = this.getToday()
			const resultYesterday: any = this.getYesterday()
			const resultLast7Days: any = this.getLast7Days()
			const resultLast30Days: any = this.getLast30Days()
			const resultLastMonth: any = this.getLastMonth()
			const resultThisMonth: any = this.getThisMonth()
			const resultThisWeek: any = this.getThisWeek()
			const resultLastWeek: any = this.getLastWeek()
			const resultThisYear: any = this.getThisYear()
			const resultLastYear: any = this.getLastYear()

			if (this.selectedDate.type == 'YEAR') {
			} else {
				this.selectedDate.type = 'CUSTOM'
			}

			if (newValue == resultToday) {
				this.selectedDate.type = 'TODAY'
				// this.firstClick = 2
			} else if (newValue == resultYesterday) {
				this.selectedDate.type = 'YESTERDAY'
				// this.firstClick = 2
			} else if (newValue == resultLast7Days) {
				this.selectedDate.type = 'LAST_7_DAYS'
			} else if (newValue == resultLast30Days) {
				this.selectedDate.type = 'LAST_30_DAYS'
			} else if (newValue == resultThisMonth) {
				this.selectedDate.type = 'THIS_MONTH'
			} else if (newValue == resultLastMonth) {
				this.selectedDate.type = 'LAST_MONTH'
			} else if (newValue == resultThisWeek) {
				this.selectedDate.type = 'THIS_WEEK'
			} else if (newValue == resultLastWeek) {
				this.selectedDate.type = 'LAST_WEEK'
			} else if (newValue == resultThisYear) {
				this.selectedDate.type = 'THIS_YEAR'
			} else if (newValue == resultLastYear) {
				this.selectedDate.type = 'LAST_YEAR'
			}

			if (newValue.includes('to')) {
				let values = newValue.split('to')
				let firstValue = values[0].split('-')
				let secondValue = values[1].split('-')
				let month = +firstValue[1] - 1
				let year = secondValue[0].trim()

				const startDate = this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd')
				const endDate = this.datePipe.transform(new Date(year, month + 1, 0), 'yyyy-MM-dd')

				let result = `${startDate} to ${endDate}`

				if (newValue == result) {
					this.selectedMonth = this.allMonths[month]
					this.selectedYear = year
					this.getDateRange()
					this.selectedDate.type = 'MONTH_YEAR'
				}

				this.firstClick = 2
			}

			this.selectedDate.value = newValue

			if (this.firstClick == 2) {
				this.firstClick = 0
				if (isGetData) {
					this.change.emit(this.selectedDate)
				}
			}

			this.firstClick += 1
			this.isMenuOpened = false
			this.cdr.detectChanges()
		}
	}

	ngAfterViewInit(): void {}

	isMenuOpened: boolean = false

	toggleMenu(e: any): void {
		e.stopPropagation()
		if (this.isLoading) {
			return
		}

		this.isMenuOpened = !this.isMenuOpened
		this.cdr.detectChanges()
	}

	clickedOutside(): void {
		this.firstClick = 0
		this.isMenuOpened = false
		this.cdr.detectChanges()
	}

	selectDate(type: string | null) {
		this.selectedDate.type = type

		if (type == 'MONTH_YEAR') {
			return
		}

		switch (type) {
			case 'TODAY':
				this.selectedDate.value = this.getToday()
				break
			case 'YESTERDAY':
				this.selectedDate.value = this.getYesterday()
				break
			case 'LAST_7_DAYS':
				this.selectedDate.value = this.getLast7Days()
				break
			case 'LAST_30_DAYS':
				this.selectedDate.value = this.getLast30Days()
				break
			case 'THIS_WEEK':
				this.selectedDate.value = this.getThisWeek()
				break
			case 'LAST_WEEK':
				this.selectedDate.value = this.getLastWeek()
				break
			case 'THIS_MONTH':
				this.selectedDate.value = this.getThisMonth()
				break
			case 'LAST_MONTH':
				this.selectedDate.value = this.getLastMonth()
				break
			case 'THIS_YEAR':
				this.selectedDate.value = this.getThisYear()
				break
			case 'LAST_YEAR':
				this.selectedDate.value = this.getLastYear()
				break
		}

		this.isMenuOpened = false
		this.cdr.detectChanges()

		this.change.emit(this.selectedDate)
	}

	getLast7Days() {
		const today = new Date()
		const sevenDaysAgo = new Date(today)
		sevenDaysAgo.setDate(today.getDate() - 6)

		const formattedToday = this.datePipe.transform(today, 'yyyy-MM-dd')
		const formattedSevenDaysAgo = this.datePipe.transform(sevenDaysAgo, 'yyyy-MM-dd')

		let lastSevenDaysRange = `${formattedSevenDaysAgo} to ${formattedToday}`
		return lastSevenDaysRange
	}

	getLastWeek() {
		const today = new Date()
		const lastSunday = new Date(today)
		lastSunday.setDate(today.getDate() - today.getDay())

		const lastWeekStart = new Date(lastSunday)
		lastWeekStart.setDate(lastSunday.getDate() - 7)

		const lastWeekEnd = new Date(lastSunday)
		lastWeekEnd.setDate(lastSunday.getDate() - 1)

		const formattedLastWeekStart = this.datePipe.transform(lastWeekStart, 'yyyy-MM-dd')
		const formattedLastWeekEnd = this.datePipe.transform(lastWeekEnd, 'yyyy-MM-dd')

		let lastWeekRange = `${formattedLastWeekStart} to ${formattedLastWeekEnd}`

		return lastWeekRange
	}

	getThisWeek() {
		const today = new Date()
		const thisSaturday = new Date(today)
		thisSaturday.setDate(today.getDate() + (6 - today.getDay()))

		const thisWeekStart = new Date(thisSaturday)
		thisWeekStart.setDate(thisSaturday.getDate() - 6)

		const formattedThisWeekStart = this.datePipe.transform(thisWeekStart, 'yyyy-MM-dd')
		const formattedThisSaturday = this.datePipe.transform(thisSaturday, 'yyyy-MM-dd')

		let thisWeekRange = `${formattedThisWeekStart} to ${formattedThisSaturday}`

		return thisWeekRange
	}

	getLast30Days() {
		const today = new Date()
		const sevenDaysAgo = new Date(today)
		sevenDaysAgo.setDate(today.getDate() - 29)

		const formattedToday = this.datePipe.transform(today, 'yyyy-MM-dd')
		const formattedSevenDaysAgo = this.datePipe.transform(sevenDaysAgo, 'yyyy-MM-dd')

		let lastSevenDaysRange = `${formattedSevenDaysAgo} to ${formattedToday}`

		return lastSevenDaysRange
	}

	getThisMonth() {
		const today = new Date()

		const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1)

		const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

		const formattedFirstDayThisMonth = this.datePipe.transform(firstDayThisMonth, 'yyyy-MM-dd')
		const formattedLastDayThisMonth = this.datePipe.transform(lastDayThisMonth, 'yyyy-MM-dd')

		const thisMonthRange = `${formattedFirstDayThisMonth} to ${formattedLastDayThisMonth}`
		return thisMonthRange
	}

	getThisYear() {
		const today = new Date()

		const firstDayThisYear = new Date(today.getFullYear(), 0, 1)
		const lastDayThisYear = new Date(today.getFullYear() + 1, 0, 0)

		const formattedFirstDayThisYear = this.datePipe.transform(firstDayThisYear, 'yyyy-MM-dd')
		const formattedLastDayThisYear = this.datePipe.transform(lastDayThisYear, 'yyyy-MM-dd')

		const thisYearRange = `${formattedFirstDayThisYear} to ${formattedLastDayThisYear}`
		return thisYearRange
	}

	getLastYear() {
		const today = new Date()
		const firstDayLastYear = new Date(today.getFullYear() - 1, 0, 1)
		const lastDayLastYear = new Date(today.getFullYear(), 0, 0)
		const formattedFirstDayLastYear = this.datePipe.transform(firstDayLastYear, 'yyyy-MM-dd')
		const formattedLastDayLastYear = this.datePipe.transform(lastDayLastYear, 'yyyy-MM-dd')
		const lastYearRange = `${formattedFirstDayLastYear} to ${formattedLastDayLastYear}`
		return lastYearRange
	}

	getLastMonth() {
		const today = new Date()
		const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
		const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)

		const formattedFirstDayLastMonth = this.datePipe.transform(firstDayLastMonth, 'yyyy-MM-dd')
		const formattedLastDayLastMonth = this.datePipe.transform(lastDayLastMonth, 'yyyy-MM-dd')

		const lastMonthRange = `${formattedFirstDayLastMonth} to ${formattedLastDayLastMonth}`
		return lastMonthRange
	}

	getToday() {
		const today = new Date()
		const formattedToday = this.datePipe.transform(today, 'yyyy-MM-dd')

		return formattedToday
	}

	getYesterday() {
		const today = new Date()
		const yesterday = new Date(today)
		yesterday.setDate(today.getDate() - 1)

		return this.datePipe.transform(yesterday, 'yyyy-MM-dd')
	}

	get months(): string[] {
		const currentMonth = new Date().getMonth() + 1
		const allMonths = cloneDeep(this.allMonths)

		if (this.selectedYear === new Date().getFullYear().toString()) {
			return allMonths.slice(0, currentMonth).reverse()
		}

		return allMonths.reverse()
	}

	get years(): string[] {
		const currentYear = new Date().getFullYear()
		const startYear = 2020
		const years: string[] = []

		for (let year = currentYear; year >= startYear; year--) {
			years.push(year.toString())
		}

		return years
	}

	onYearChange(e: any): void {
		this.selectedYear = e?.target?.value
		const currentYear = new Date().getFullYear().toString()

		this.selectedMonth = null
		// if (this.selectedYear != currentYear) {
		// 	if (!this.months.includes(this.selectedMonth)) {
		// 		this.selectedMonth = 'Dec'
		// 	}
		// } else {
		// 	if (!this.months.includes(this.selectedMonth)) {
		// 		this.selectedMonth = this.allMonths[new Date().getMonth()]
		// 	}
		// }

		this.getDateRange()
		this.change.emit(this.selectedDate)
		// this.isMenuOpened = false
		this.cdr.detectChanges()
	}

	onMonthChange(e: any): void {
		this.getDateRange()
		this.change.emit(this.selectedDate)
		this.isMenuOpened = false
		this.cdr.detectChanges()
	}

	getDateRange() {
		let year: any = cloneDeep(this.selectedYear)
		year = parseInt(year, 10)
		if (this.selectedMonth) {
			let month = this.allMonths.indexOf(this.selectedMonth)

			const startDate = this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd')
			const endDate = this.datePipe.transform(new Date(year, month + 1, 0), 'yyyy-MM-dd')

			this.selectedDate.value = `${startDate} to ${endDate}`
			this.selectedDate.type = 'MONTH_YEAR'
		} else {
			const startDateOfYear = this.datePipe.transform(new Date(year, 0, 1), 'yyyy-MM-dd')
			const endDateOfYear = this.datePipe.transform(new Date(year, 11, 31), 'yyyy-MM-dd')

			this.selectedDate.value = `${startDateOfYear} to ${endDateOfYear}`
			this.selectedDate.type = 'YEAR'
		}
	}

	selectBar(params: any) {
		switch (this.selectedDate.type) {
			case 'YEAR':
			case 'THIS_YEAR':
			case 'LAST_YEAR':
				let values = params.name ? params.name.split('-') : params.value.split('-')
				let month = +values[0]
				let year = values[1]

				this.selectedMonth = this.allMonths[month - 1]
				this.selectedYear = year
				this.selectedDate = {
					...this.selectedDate,
					type: 'MONTH_YEAR',
				}
				this.getDateRange()
				this.cdr.detectChanges()
				this.change.emit(this.selectedDate)
				break
			case 'MONTH_YEAR':
			case 'THIS_MONTH':
			case 'LAST_MONTH':
			case 'THIS_WEEK':
			case 'LAST_WEEK':
			case 'CUSTOM':
				if (this.selectedDate.type == 'CUSTOM' && !this.selectedDate.value.includes('to')) {
					return
				}

				this.selectedDate = {
					...this.selectedDate,
					type: 'CUSTOM',
				}
				this.selectedDate.value = params.name
				this.cdr.detectChanges()
				this.change.emit(this.selectedDate)

				break
		}
	}
}
